import "./Header.scss"

function Header() {
    return (
      <header>
        <h1>Élodie Boin·Zanchi</h1>
        <h2>photographie ·  éditions web et imprimée</h2>
        {/* <MainNav /> */}
      </header>
    )
  }
  
  export default Header