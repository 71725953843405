import { FC, useEffect } from "react"
import Header from "../../components/Header/Header"
import "./Page.scss"


type PageProps = {
  title: string
  children?: React.ReactNode
}

const Page: FC<PageProps> = ({ title, children }) => {
  // to always have the correct meta title
  useEffect(() => {
    document.title = ` el.bz · ${title}`
    window.scrollTo(0, 0)
  }, [title])

  return (
    <>
    <Header/>
      <main>{children}</main>
    </>
  )
}

export default Page