import TempMsg from "../../components/TempMsg/TempMsg"
import Page from "../../containers/Page/Page"

function Home() {
    return (
        <Page title="accueil">
            <TempMsg/>
        </Page>
    )
}

export default Home