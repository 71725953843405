import "./TempMsg.scss"
// import Agave from "../../assets/img/EBZ_AGAVE1.jpg"

function TempMsg() {
  return (
    <div className="temporary-msg">
      {/* <img src={Agave} alt="Agave"/> */}
      <p>Site en cours de fabrication.</p>
      <a href="mailto:elodie.bz@distributiondusensible.eu">
        elodie.bz@distributiondusensible.eu
      </a>
    </div>
  )
}

export default TempMsg
